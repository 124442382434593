import React from 'react';
import PropTypes from "prop-types";
import { Dropdown, Space } from 'antd';
import { HiChevronDown } from 'react-icons/hi';

export default function CustomDropdown({ items, name }) {
  const menuItems = items.map(item => ({
    key: item.key,
    label: item.label,
    onClick: item.onClick, // Ensure to handle click events if needed
  }));

  return (
    <Dropdown
      menu={{ items: menuItems }}
      trigger={['click']}
      overlayClassName="custom-dropdown"
    >
      <button className="custom-dropdown-button">
        <Space>
          <span className='font-medium'>{name}</span>
          <HiChevronDown className='text-xl' />
        </Space>
      </button>
    </Dropdown>
  );
}

CustomDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired
};
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cities: [],
  governments: [],
  academic_degrees: [],
  military_statuses: [],
  social_statuses: [],
  job_types: [],
  job_degrees: [],
  department_specialities: [],
  departments: [],
  administrations: [],
  jobs: [],
  job_roles: [],
  job_status: [],
  payment_method: [],
  employment_employee_type: [],
  files: {
    employee_image: null,
    contract: [],
    documents: [],
  },
  work_days: [],
  employees: {
    data: [],
    isLoading: false,
    errors: [],
  },
  shifts: {
    data: [],
    isLoading: false,
    errors: [],
  },
  cabinets: [],
};
const stafAffairsSlice = createSlice({
  name: "stafAffairs",
  initialState,
  reducers: {
    setCities: (state, action) => {
      state.cities = action.payload;
    },
    setGovernments: (state, action) => {
      state.governments = action.payload;
    },
    setJobDegreeMedical: (state, action) => {
      state.job_degrees = action.payload;
    },
    setJobDegreeMilitary: (state, action) => {
      state.military_statuses = action.payload;
    },
    setJobDegreeAcademic: (state, action) => {
      state.academic_degrees = action.payload;
    },
    setSocialStatus: (state, action) => {
      state.social_statuses = action.payload;
    },
    setJobStatus: (state, action) => {
      state.job_status = action.payload;
    },
    setJobType: (state, action) => {
      state.job_types = action.payload;
    },
    setDepartmentsSpeciality: (state, action) => {
      state.department_specialities = action.payload;
    },
    setDepartment: (state, action) => {
      state.departments = action.payload;
    },
    setAdministration: (state, action) => {
      state.administrations = action.payload;
    },
    setJob: (state, action) => {
      state.jobs = action.payload;
    },
    setJobsRole: (state, action) => {
      state.job_roles = action.payload;
    },
    setPaymentTypes: (state, action) => {
      state.payment_method = action.payload;
    },
    setEmploymentType: (state, action) => {
      state.employment_employee_type = action.payload;
    },
    setWorkDays: (state, action) => {
      state.work_days = action.payload;
    },
    uploadFiles: (state, action) => {
      console.log("action");
      console.log(action);
      state.files = { ...state.files, ...action.payload };
    },
    setEmployees: (state, action) => {
      state.employees = { ...state.employees, ...action.payload };
    },
    setShifts: (state, action) => {
      state.shifts = { ...state.shifts, ...action.payload };
    },
    setCabinet: (state, action) => {
      state.cabinets = action.payload;
    },
  },
});

export const {
  setCities,
  setGovernments,
  setJobDegreeMedical,
  setJobDegreeMilitary,
  setJobDegreeAcademic,
  setSocialStatus,
  setJobType,
  setDepartmentsSpeciality,
  setDepartment,
  setAdministration,
  setJob,
  setJobsRole,
  setPaymentTypes,
  setEmploymentType,
  setJobStatus,
  setWorkDays,
  uploadFiles,
  setEmployees,
  setShifts,
  setCabinet,
} = stafAffairsSlice.actions;

export default stafAffairsSlice.reducer;

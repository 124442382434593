import React, { useEffect, useState } from "react";
import { ConfigProvider } from "antd";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Header from "./components/layout/header/Header";
import Sidebar from "./components/layout/sidebar/Sidebar";
import Navigations from "./components/navigations/Navigations";
import { useTranslation } from "react-i18next";

const Main = styled.div`
  .content {
    margin-inline-start: ${(props) =>
      props.userToken && props.isExtend
        ? "200px"
        : props.userToken && !props.isExtend
        ? "60px"
        : null};
  }
`;
function App() {
  const { t, i18n } = useTranslation();
  const isExtend = useSelector((state) => state.sidebar.isActive);
  const userToken = useSelector((state) => state.auth.userToken);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    document.documentElement.dir = i18n.language === "en" ? "ltr" : "rtl";
    document.documentElement.lang = i18n.language === "en" ? "en" : "ar";
  }, [i18n.language]);

  useEffect(() => {
    if (!userToken || userToken === "undefined") {
      return setIsLoggedIn(false);
    }
    setIsLoggedIn(true);
  }, [isLoggedIn]);

  return (
    <ConfigProvider
      direction={`${i18n === "en" ? "ltr" : "rtl"}`}
      theme={{
        token: {
          fontFamily: `${i18n === "en" ? "Plus Jakarta Sans" : "Tajawal"}`,
        },
      }}
    >
      <Main className="relative" isExtend={isExtend} userToken={userToken}>
        {userToken && <Sidebar />}
        <div className="content">
          {userToken && <Header />}
          <div className="min-h-screen bg-main-100">
            {/* routes */}
            <Navigations />
          </div>
        </div>
      </Main>
    </ConfigProvider>
  );
}

export default App;

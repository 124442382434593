import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { $axios } from '../../api/axios';

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  itemsCount: 0,
  errors: []
}

export const getServicesTypes = createAsyncThunk(
  "settings/accounts/medical-service-type",
  async (_, thunkApi) => {
    try {
      const { data } = await $axios.get(
        `/api/settings/accounts/medical-service-type`,
        {
          headers: {
            "Authorization": `Bearer ${thunkApi.getState().auth.userToken}`
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

const servicesTypeSlice = createSlice({
  name: "medical-service-type",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getServicesTypes.pending, (state) => {
      state.isLoading = true;
      state.errors = [];
    })
    builder.addCase(getServicesTypes.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [];
      state.data = payload.data;
    //   state.pages = Math.ceil(payload.meta.total / payload.meta.per_page);
      state.itemsCount = payload.meta.total;
    })
    builder.addCase(getServicesTypes.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    })
  }
});

export default servicesTypeSlice.reducer
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { $axios } from '../../api/axios';

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  itemsCount: 0,
  errors: []
}

export const getJobs = createAsyncThunk(
  "settings/jobs",
  async (filter, thunkApi) => {
    let MY_OBJECT = {...filter};
  
      for(let i in MY_OBJECT){
        if(MY_OBJECT[i] === undefined){
        delete MY_OBJECT[i];
      }
    }
    try {
    const { data } = await $axios.get(
        `/api/settings/jobs`,{
          params: {...MY_OBJECT},
          headers: {
              "Authorization": `Bearer ${thunkApi.getState().auth.userToken}`
          }
        }
    );
    return thunkApi.fulfillWithValue(data);
    } catch (error) {
    return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

const jobSlice = createSlice({
  name: "jobs",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getJobs.pending, (state) => {
      state.isLoading = true;
      state.errors = [];
    })
    builder.addCase(getJobs.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [];
      state.data = payload.data;
    //   state.pages = Math.ceil(payload.meta.total / payload.meta.per_page);
      state.itemsCount = payload.meta.total;
    })
    builder.addCase(getJobs.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    })
  }
});

export default jobSlice.reducer
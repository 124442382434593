import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/authSlice";
import sidebarSlice from "./sidebar/sidebarSlice";
import patientsSlice from "./reception/patientsSlice";
import stafAffairsSlice from "./stafAffairs/stafAffairsSlice";
import departmentsSlice from "./departments/departmentsSlice";
import jobSlice from "./jobs/jobSlice";
import accountsSlice from "./accounts/accountsSlice";
import SpecialitiesSlice from './specialities/SpecialitiesSlice';
import diagnosisCategSlice from "./diagnosis/diagnosisCategSlice";
import diagnosisTypeSlice from "./diagnosis/diagnosisTypeSlice";
import SurgeriesTypesSlice from "./accounts/surgeriesTypesSlice";
import servicesTypesSlices from "./accounts/servicesTypesSlices";
import roomSlice from "./hospital/roomSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    sidebar: sidebarSlice,
    patients: patientsSlice,
    stafAffairs: stafAffairsSlice,
    departments: departmentsSlice,
    jobs: jobSlice,
    accounts: accountsSlice,
    specialities: SpecialitiesSlice,
    diagnosisCategories: diagnosisCategSlice,
    diagnosisTypes: diagnosisTypeSlice,
    surgeriesTypes: SurgeriesTypesSlice,
    servicesTypes: servicesTypesSlices,
    rooms: roomSlice
  },
});
export default store;

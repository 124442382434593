import { Badge, Space, Dropdown, Button } from "antd";
import React from "react";
import { BiMessageDetail } from "react-icons/bi";
import { RiFileListLine } from "react-icons/ri";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { MdMenu } from "react-icons/md";
import { useTranslation } from "react-i18next";
import CustomDropdwon from "../../shared/customDropdown/CustomDropdwon";
import { HeaderContent } from "./Style"; // Ensure your styles are polished
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../../store/sidebar/sidebarSlice";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../store/auth/authSlice";
import '../header/common.css';

export default function Header() {
  const isExtend = useSelector((state) => state.sidebar.isActive);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();


  const handleLogout = async () => {
    dispatch(logout());
    navigate("/login"); 
  };

  const handleProfileClick = () => {
    navigate('/profilepage'); 
  };

  const changeLanguage = (lng) => {
    localStorage.setItem("language", lng);
    i18n.changeLanguage(lng);
  };

  const langItems = [
    {
      label: <span onClick={() => changeLanguage("en")}>EN</span>,
      key: "0",
    },
    {
      label: <span onClick={() => changeLanguage("ar")}>AR</span>,
      key: "1",
    },
  ];

  const userList = [
    {
      label: (
        <span onClick={handleProfileClick}>Profile</span> 
      ),
      key: "0",
    },
    {
      label: (
        <span onClick={handleLogout}>
          Logout
        </span>
      ),
      key: "2",
    },
  ];

  return (
    <HeaderContent className="border-b border-gray-200 shadow-sm">
      <div className="container py-4 flex justify-between items-center">
        <MdMenu
          className={`text-black text-2xl cursor-pointer ${isExtend ? "block" : "hidden"}`}
          onClick={() => dispatch(toggleSidebar(!isExtend))}
        />
        <Space size="large" className="control-btns">
          <CustomDropdwon items={langItems} name={t("layout.header.language")} />
          <CustomDropdwon items={userList} name={t("layout.header.user")} />
        </Space>
      </div>
    </HeaderContent>
  );
}
import axios from "axios";
import Cookies from "js-cookie";
import { baseURL } from "./config";
// import {
//   AUTHENTICATION_TOKEN,
//   SECRET_KEY_TOKEN,
// } from "../helpers/constants/StaticKeys";
// // import Storage from "../helpers/Storage";
// import CryptoJS from "crypto-js";
export const $axios = axios.create({
  baseURL: baseURL,
  headers: {
    common: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  },
});
export const $securedAxios = axios.create({
  baseURL: baseURL,
  headers: {
    common: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  },
});

const requestHandler = (request) => {
  const authToken = Cookies.get("userToken" ?? null);
  let headers = {};
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }
  request.headers.common = {
    ...request.headers.common,
    ...headers,
  };

  return request;
};

const responseHandler = (response) => {
  if (response.status === 401) {
    window.location = "/login";
  }

  return response;
};

const errorHandler = (error) => {
  return Promise.reject(error);
};

// UNSECURED RESPONSE HANDLER
$axios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

// SECURED REQUEST HANDLER
$securedAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

// SECURED RESPONSE HANDLER
$securedAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

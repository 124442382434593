import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contracts: {
    data: [],
    isLoading: false,
    errors: [],
  },
  cabinetsBudgets: {
    data: [],
    isLoading: false,
    errors: [],
  },
  extrasDiscounts: {
    data: [],
    isLoading: false,
    errors: [],
  },
  priceList: {
    data: [],
    isLoading: false,
    errors: [],
  },
};
const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    set: (state, action) => {
      console.log({ ...state, ...action.payload });
      state = { ...state, ...action.payload };
    },
    setContracts: (state, action) => {
      console.log(action);
      console.log({ ...state.contracts, ...action.payload });

      state.contracts = { ...state.contracts, ...action.payload };
    },
    setExtrasDiscounts: (state, action) => {
      state.extrasDiscounts = { ...state.extrasDiscounts, ...action.payload };
    },
    setCabinetsBudgets: (state, action) => {
      state.cabinetsBudgets = { ...state.cabinetsBudgets, ...action.payload };
    },
    setPriceList: (state, action) => {
      console.log(action);
      state.priceList = { ...state.priceList, ...action.payload };
    },
  },
});

export const {
  set,
  setContracts,
  setExtrasDiscounts,
  setCabinetsBudgets,
  setPriceList,
} = accountsSlice.actions;

export default accountsSlice.reducer;

import * as React from 'react'
import { Route, Routes } from "react-router-dom";
const Employees = React.lazy(() => import('../../pages/settings/stafAffairs/employees/Employees'))
const Shifts = React.lazy(() => import('../../pages/settings/stafAffairs/shifts/Shifts'))
const Contracts = React.lazy(() => import('../../pages/settings/accounts/Contract/Contracts'))
const CabinetsBudgets = React.lazy(() => import('../../pages/settings/accounts/CabinetsBudgets/CabinetsBudgets'))
const ClinicsRevealed = React.lazy(() => import('../../pages/settings/accounts/ClinicsRevealed/ClinicsRevealed'))
const ExtrasDiscounts = React.lazy(() => import('../../pages/settings/accounts/ExtrasDiscounts/ExtrasDiscounts'))
const PriceList = React.lazy(() => import('../../pages/settings/accounts/PriceList/PriceList'))
const Services = React.lazy(() => import('../../pages/settings/accounts/Services/Services'))
const ServicesTypes = React.lazy(() => import('../../pages/settings/accounts/ServicesTypes/ServicesTypes'))
const Surgeries = React.lazy(() => import('../../pages/settings/accounts/Surgeries/Surgeries'))
const SurgeriesTypes = React.lazy(() => import('../../pages/settings/accounts/SurgeriesTypes/SurgeriesTypes'))
const TransferEntities = React.lazy(() => import('../../pages/settings/accounts/TransferEntities/TransferEntities'))
const Complaints = React.lazy(() => import('../../pages/settings/medicalData/complaints/Complaints'))
const DiagnosesCategory = React.lazy(() => import('../../pages/settings/medicalData/diagnosisCategory/DiagnosisCategory'))
const DiagnosisDiseases = React.lazy(() => import('../../pages/settings/medicalData/diagnosisDiseases/DiagnosisDiseases'))
const DiagnosisTypes = React.lazy(() => import('../../pages/settings/medicalData/diagnosisTypes/DiagnosisTypes'))
const EffectiveData = React.lazy(() => import('../../pages/settings/medicalData/effectiveData/EffectiveData'))
const ExternalExaminations = React.lazy(() => import('../../pages/settings/medicalData/externalExaminations/ExternalExaminations'))
const MedicalOrders = React.lazy(() => import('../../pages/settings/medicalData/medicalOrders/MedicalOrders'))
const Prohibitions = React.lazy(() => import('../../pages/settings/medicalData/prohibitions/Prohibitions'))
const SymptomsSigns = React.lazy(() => import('../../pages/settings/medicalData/SymptomsSigns/SymptomsSigns'))
const UltrasoundExaminations = React.lazy(() => import('../../pages/settings/medicalData/ultrasoundExaminations/UltrasoundExaminations'))
const Clinic = React.lazy(() => import('../../pages/clinic/Clinic'))
const Dashboard = React.lazy(() => import('../../pages/dashboard/Dashboard'))
const Login = React.lazy(() => import('../../pages/login/Login'))
const Appointments = React.lazy(() => import('../../pages/reception/appointments/Appointments'))
const Attendance = React.lazy(() => import('../../pages/reception/attendance/Attendance'))
const Patients = React.lazy(() => import('../../pages/reception/patients/Patients'))
const Reception = React.lazy(() => import('../../pages/reception/Reception'))
const Accounts = React.lazy(() => import('../../pages/settings/accounts/Accounts'))
const Assets = React.lazy(() => import('../../pages/settings/assets/Assets'))
// const Contracts = React.lazy(() => import('../../pages/settings/contracts/Contracts'))
const MedicalData = React.lazy(() => import('../../pages/settings/medicalData/MedicalData'))
const Operations = React.lazy(()=> import('../../pages/settings/operations/Operations'))
const StafAffairs = React.lazy(() => import('../../pages/settings/stafAffairs/StafAffairs'))
const Pharmacy = React.lazy(() => import('../../pages/settings/pharmacy/Pharmacy'))
const Settings = React.lazy(() => import('../../pages/settings/Settings'))
const Hospital = React.lazy(() => import('../../pages/settings/hospital/Hospital'))
const Stores = React.lazy(() => import('../../pages/settings/stores/Stores'))
const ThirdParty = React.lazy(() => import('../../pages/settings/thirdParty/ThirdParty'))
const ProtectedRoutes = React.lazy(() => import('./ProtectedRoutes'))
const BasicInfo = React.lazy(() => import('../../pages/settings/hospital/basicInfo/BasicInfo'))
const Departments = React.lazy(() => import('../../pages/settings/hospital/departments/Departments'))
const DepartmentSpecialities = React.lazy(() => import('../../pages/settings/hospital/departmentSpecialities/DepartmentSpecialities'))
const Rooms = React.lazy(() => import('../../pages/settings/hospital/rooms/Rooms'))
const Beds = React.lazy(() => import('../../pages/settings/hospital/beds/Beds'))
const Jobs = React.lazy(() => import('../../pages/settings/hospital/jobs/Jobs'))
const JobRoles = React.lazy(() => import('../../pages/settings/hospital/jobsRoles/JobsRoles'))

const ProfilePage = React.lazy(()=>import('../../pages/user/ProfilePage'))
const ScreenshotPage = React.lazy(()=>import('../../pages/screenshot/MediaStore'))

const Navigations = () => {
  return (
    <Routes>
      <Route path="/login" 
        element={
          <React.Suspense fallback='loading...'>
            <Login />
          </React.Suspense>
        } />
      <Route 
        element={
            <React.Suspense fallback='loading...'>
              <ProtectedRoutes />
            </React.Suspense>
          }>
        <Route path="/" index 
          element={
            <React.Suspense fallback='loading...'>
              <Dashboard />
            </React.Suspense>
          } />
        <Route path="/reception" element={<React.Suspense fallback='loading...'><Reception /></React.Suspense>}>
          <Route index element={<React.Suspense fallback='loading...'><Patients /></React.Suspense>} />
          <Route path="patients" element={<React.Suspense fallback='loading...'><Patients /></React.Suspense>} />
          <Route path="appointments" element={<React.Suspense fallback='loading...'><Appointments /></React.Suspense>} />
          <Route path="attendance" element={<React.Suspense fallback='loading...'><Attendance /></React.Suspense>}/>
        </Route>
        
        <Route path="/clinic" element={
          <React.Suspense fallback='loading...'>
              <Clinic />
            </React.Suspense>} />

        <Route path="/settings" element={<React.Suspense fallback='loading...'><Settings /> </React.Suspense>}>
          <Route index  element={<React.Suspense fallback='loading...'><Hospital /></React.Suspense>} />

          <Route path="hospital" element={<React.Suspense fallback='loading...'><Hospital /> </React.Suspense>}>
            <Route index element={<React.Suspense fallback='loading...'><BasicInfo /></React.Suspense>}/>
            <Route path='basic_info' element={ <React.Suspense fallback='loading...'><BasicInfo /></React.Suspense>}/>
            <Route path="departments" element={<React.Suspense fallback='loading...'> <Departments /></React.Suspense>}/>
            <Route path="departments_specialities" element={<React.Suspense fallback='loading...'><DepartmentSpecialities /></React.Suspense>}/>
            <Route path="beds" element={<React.Suspense fallback='loading...'> <Beds /></React.Suspense>}/>
            <Route path="rooms" element={<React.Suspense fallback='loading...'> <Rooms /></React.Suspense>}/>
            <Route path="jobs" element={<React.Suspense fallback='loading...'><Jobs /></React.Suspense>}/>
            <Route path="jobs-roles" element={<React.Suspense fallback='loading...'> <JobRoles /></React.Suspense>}/>   
          </Route>

          <Route path="staf-affairs" element={<React.Suspense fallback='loading...'><StafAffairs /></React.Suspense>}>
              <Route index element={<React.Suspense fallback='loading...'><Employees/></React.Suspense>}/>
              <Route path="employees" element={<React.Suspense fallback='loading...'><Employees/></React.Suspense>}/>
              <Route path="shifts" element={<React.Suspense fallback='loading...'><Shifts/></React.Suspense>}/>
          </Route>

          <Route path="accounts" element={<React.Suspense fallback='loading...'><Accounts /></React.Suspense>} >
            <Route index element={<React.Suspense fallback='loading...'> <Contracts /></React.Suspense>}/>   
            <Route path="contracts" element={<React.Suspense fallback='loading...'> <Contracts /></React.Suspense>}/>   
            <Route path="customer_price_lists" element={<React.Suspense fallback='loading...'> <PriceList /></React.Suspense>}/>   
            <Route path="extras_and_bonuses" element={<React.Suspense fallback='loading...'> <ExtrasDiscounts /></React.Suspense>}/>   
            <Route path="budgets_cabinets" element={<React.Suspense fallback='loading...'> <CabinetsBudgets /></React.Suspense>}/>   
            <Route path="transfer_entities" element={<React.Suspense fallback='loading...'> <TransferEntities /></React.Suspense>}/>   
            <Route path="clinic_revealed" element={<React.Suspense fallback='loading...'> <ClinicsRevealed /></React.Suspense>}/>   
            <Route path="services_types" element={<React.Suspense fallback='loading...'> <ServicesTypes /></React.Suspense>}/>   
            <Route path="services" element={<React.Suspense fallback='loading...'> <Services /></React.Suspense>}/>   
            <Route path="surgeries_types" element={<React.Suspense fallback='loading...'> <SurgeriesTypes /></React.Suspense>}/>   
            <Route path="surgeries" element={<React.Suspense fallback='loading...'> <Surgeries /></React.Suspense>}/>   
          </Route>

          <Route path="third-party" element={
            <React.Suspense fallback='loading...'>
              <ThirdParty />
            </React.Suspense>} />

          <Route path="stores" element={
            <React.Suspense fallback='loading...'>
              <Stores />
            </React.Suspense>} />

          <Route path="assets" element={
            <React.Suspense fallback='loading...'>
              <Assets />
            </React.Suspense>} />

          <Route path="medical-data" element={<React.Suspense fallback='loading...'><MedicalData /></React.Suspense>}>
                  {/* medical data sub routes */}
              <Route index element={<React.Suspense fallback='loading...'><Complaints /></React.Suspense>} />
              <Route index path="complaints" element={<React.Suspense fallback='loading...'><Complaints /></React.Suspense>} />
              <Route path="symptoms-signs" element={<React.Suspense fallback='loading...'><SymptomsSigns /></React.Suspense>} />
              <Route path="effective-data" element={<React.Suspense fallback='loading...'><EffectiveData /></React.Suspense>} />
              <Route path="ultrasound-examinations" element={<React.Suspense fallback='loading...'><UltrasoundExaminations /></React.Suspense>} />
              <Route path="external-Examinations" element={<React.Suspense fallback='loading...'><ExternalExaminations /></React.Suspense>} />
              <Route path="diagnosis-category" element={<React.Suspense fallback='loading...'><DiagnosesCategory /></React.Suspense>} />
              <Route path="diagnosis-types" element={<React.Suspense fallback='loading...'><DiagnosisTypes /></React.Suspense>} />
              <Route path="diagnosis-diseases" element={<React.Suspense fallback='loading...'><DiagnosisDiseases /></React.Suspense>} />
              <Route path="medical-orders" element={<React.Suspense fallback='loading...'><MedicalOrders /></React.Suspense>} />
              <Route path="prohibitions" element={<React.Suspense fallback='loading...'><Prohibitions /></React.Suspense>} />
          </Route>

          <Route path="operation" element={
            <React.Suspense fallback='loading...'>
              <Operations />
            </React.Suspense>} />

          <Route path="pharmacy" element={
            <React.Suspense fallback='loading...'>
              <Pharmacy />
            </React.Suspense>} />
        </Route>

        <Route path="screenshot" element={
            <React.Suspense fallback='loading...'>
              <ScreenshotPage />
            </React.Suspense>} />

        <Route path="profilepage" element={
            <React.Suspense fallback='loading...'>
              <ProfilePage />
            </React.Suspense>} />
      </Route>
    </Routes>
  );
};

export default Navigations;




import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from "js-cookie";
import { $axios } from '../../api/axios';

const initialState = {
  loading: false,
  userToken: Cookies.get("userToken") ?? null,
  userProfile: null, // Add userProfile to the state
  error: null,
  success: false,
};

// Async thunk for user login
export const userLogin = createAsyncThunk(
  'auth/login',
  async ({ email, password }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await $axios.post(
        `/api/login`,
        { email, password },
        config
      );

      const token = data.entity.access_token; // Access the token
      console.log("Token Received:", token); // Log the received token

      Cookies.set("userToken", token); // Set the token in cookies
      return fulfillWithValue(token); // Return the token
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);


export const fetchUserProfile = createAsyncThunk(
  'auth/fetchUserProfile',
  async (_, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.auth.userToken; 

    try {
      const { data } = await $axios.get('api/user/details', {
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      });
      return data.user; 
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      Cookies.remove('userToken');
      state.loading = false;
      state.userToken = null;
      state.userProfile = null; // Reset userProfile on logout
      state.error = null;
    },
  },
  extraReducers: {
    // login user
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userToken = payload;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // fetch user profile
    [fetchUserProfile.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchUserProfile.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userProfile = payload; // Store user profile data
    },
    [fetchUserProfile.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
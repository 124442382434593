import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { $axios } from '../../api/axios';

let initialState = {
  isLoading: false,
  data: [],
  total: 0,
  itemsCount: 0,
  errors: []
}

export const getDiagnosisCateg = createAsyncThunk(
  "settings/medical-data/diagnosis-categories/getDiagnosisCateg",
  async (filter, thunkApi) => {
     let MY_OBJECT = {...filter};

    for(let i in MY_OBJECT){
      if(MY_OBJECT[i] === undefined){
      delete MY_OBJECT[i];
    }
  }
    try {
      const { data } = await $axios.get(
        `/api/settings/medical-data/diagnosis-categories`,
        {
          params: {...MY_OBJECT},
          headers: {
            "Authorization": `Bearer ${thunkApi.getState().auth.userToken}`
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  },
 
);

const diagnosisCategSlice = createSlice({
  name: "diagnosis-categories",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDiagnosisCateg.pending, (state) => {
      state.isLoading = true;
      state.errors = [];
    })
    builder.addCase(getDiagnosisCateg.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [];
      state.data = payload.data;
      // state.pages = Math.ceil(payload.meta.total / payload.meta.per_page);
      state.total = payload.meta.total;
      state.itemsCount = payload.meta.total;
    })
    builder.addCase(getDiagnosisCateg.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    })
  }
});

export default diagnosisCategSlice.reducer